/*eslint no-dupe-keys: "off"*/

import React from 'react';
import { Title, Text, Container, Button, Overlay, createStyles, rem, MantineProvider } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import {IconSearch, NamedLink} from '../../components';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';

import css from './HeroImageBackground.module.css'

import variables from '../../styles/globals.module.scss';

/* 
    Small: 550,
    Medium: 768,
    Large: 1024,
    LargeWithPaddings: 1128,
    XLarge: 1921
*/
const breakpoints = {
  "320": 320,
  "360": 360,
  "375": 375,
  "390": 390,
  "393": 393,
  "412": 412,
  "414": 414,
  xs: parseInt(variables.viewportSmallPixels),
  sm: parseInt(variables.viewportMediumPixels),
  "810": 810,
  md: parseInt(variables.viewportLargePixels),
  lg: parseInt(variables.viewportLargeWithPaddingsPixels),
  "1280": 1280,
  "1366": 1366,
  "1440": 1440,
  "1536": 1536,
  "1600": 1600,
  xl: parseInt(variables.viewportXLargePixels),
  xxl: 2560,    
};

const qualityBreakPoints = {
  "320": [100,90, 50],
  "360":  [100,90, 50],
  "375":  [100,90, 50],
  "390":  [100,90, 50],
  "393":  [100,90, 50],
  "412":  [100,90, 50],
  "414":  [100,90, 50],  
  xs:     [100,90, 50],
  sm:     [70, 50, 20],
  "810" : [70, 50, 20],
  md:     [70, 30, 20],
  lg:     [70, 30, 20],
  "1280": [70, 30, 20],
  "1366": [70, 30, 20],
  "1440": [70, 30, 20],
  "1536": [70, 30, 20],
  "1600": [70, 30, 20],
  xl: [70, 30, 20],
  xxl: [70, 30, 20]
}

const baseImageUrl = "https://tourly-media.imgix.net/media/madeira-drone-view.jpeg?auto=format&fit=crop&blend-pad=5&blend-alpha=0&";
const cropForSmallerScreens = "rect=center,middle,4012,2649&";
const smallScreenThreshold = parseInt(variables.viewportSmallPixels);
const DEFAULT_IMAGE_QUALITY = 20;

const sortedBreakpoints = Object.entries(breakpoints).sort( (a,b) => {return a[1] - b[1]});

const useStyles = createStyles((theme) => {
  return {
    wrapper: {
      position: 'relative',
    },

    inner: {
      position: 'absolute',
      // top: '50%',
      // left: '50%',
      // transform: 'translate(-50%, -50%)',
      margin: 'auto',
      zIndex: 1,
    },

    title: {
      fontWeight: 800,
      letterSpacing: rem(-1),
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
      color: theme.white,
      marginBottom: theme.spacing.xs,
      textAlign: 'center',
      fontFamily: `Quicksand, ${theme.fontFamily}`,
      /* 
        Small: 550,
        Medium: 768,
        Large: 1024,
        LargeWithPaddings: 1128,
        XLarge: 1921
      */
      [theme.fn.largerThan('lg')]: {
        fontSize: rem(44),
      },
      [theme.fn.smallerThan('lg')]: {
        fontSize: rem(36),
      },
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(30),
      },
      [theme.fn.smallerThan('md')]: {
        fontSize: rem(28),
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: rem(20),
      },
    },

    highlight: {
      color: theme.colors[theme.primaryColor][4],
    },

    description: {
      color: theme.colors.gray[0],
      textAlign: 'center',

      [theme.fn.smallerThan('xs')]: {
        fontSize: theme.fontSizes.md,
      },
      [theme.fn.largerThan('md')]: {
        fontSize: theme.fontSizes.lg,
      },
    },

    controls: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,

      [theme.fn.smallerThan('xs')]: {
        flexDirection: 'column',
      },
    },

    control: {
      height: rem(42),
      fontSize: theme.fontSizes.md,
      color: "var(--matterColorLightTransparent);",
      margin: "auto auto",

      '&:not(:first-of-type)': {
        marginLeft: theme.spacing.md,
      },

      backgroundColor: '#4dabf7',
      boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.4)",
      transition: "0.5s",
      
      animationDelay: "0.8s",

      "&:hover,&:focus" : {
          color: "var(--matterColorBright);",
          backgroundColor: '#60b3f7',
          boxShadow: "none",
      },
    },

    secondaryControl: {
      color: theme.white,
      backgroundColor: 'rgba(255, 255, 255, .4)',

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, .45) !important',
      },
    }
  }
});

const getImageSrcSet = (bp, width) => {
  if (bp==="") {
    const aux = sortedBreakpoints.find( ([_,y]) => width <= y );
    // If not found, means that width of viewport received is larger than xl => return xl and reset width to xl pixels
    if (aux===undefined) {
      bp = "xxl";
      width = breakpoints.xl;
    } else {
      bp = aux[0];
    }
  }
  const qlBreakpoint = Object.entries(qualityBreakPoints).find( ([x,_]) => x === bp );
  var result = "";
  const qualityValues = qlBreakpoint[1];
  const croppedBaseImageUrl = `${baseImageUrl}${width <= smallScreenThreshold ? cropForSmallerScreens : ''}`;
  qualityValues.forEach( (quality, index) => {
    result = result.concat(`${croppedBaseImageUrl}w=${width}&q=${quality}&dpr=${index+1} ${(index+1)}x`, ",");
  });
  return result;
}

const theme = {
  fontFamily: 'Quicksand, serif' ,
  breakpoints: `${breakpoints}`,
};

const mediaQueryForBreakpoint = (bp, width) => {
  const qlBreakpoints = sortedBreakpoints.filter( ([_,y]) => y < width );
  const qlBreakpoint = qlBreakpoints[qlBreakpoints.length -1];
  const minWidthPixels = bp === "320" ? 1 : qlBreakpoint[1] + 1;
  const maxWidthPixels = bp === "xxl" ? -1 : width;
  return `(min-width: ${minWidthPixels}px)` + (maxWidthPixels!=-1 ? ` and (max-width: ${maxWidthPixels}px)` : '');
}

const HeroImageBackground = (props) => {
  const { classes } = useStyles();
  const { ref: heroRef, width: heroWidth } = useElementSize();
  const {intl, title, description, highlight, ctaText, alt} = props
  const wrapperClasses = clsx(classes.wrapper, css.wrapperImages);

  //const heroSrcSet = heroWidth > 0 ? getImageSrcSet("", heroWidth) : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  const heroSrc =  heroWidth > 0 ? `${baseImageUrl}w=${heroWidth}&q=${DEFAULT_IMAGE_QUALITY}` : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  var animationDelayforDescription = 0;
  const titleMessage = (() => {
    try {
      const initialValue = "";
      var message = intl.formatMessage({ id: title.id}, title.values);
      // Split message by words and add animation to each word
      message = message.split(/\s/).reduce((acc,curr,currIndex) => {
        const animationCSS = `animation: fade-in 0.8s ${0.1 * (currIndex+1)}s forwards cubic-bezier(0.11, 0, 0.5, 0);`;
        animationDelayforDescription = 0.1 * (currIndex+1);
        return acc+=`<span id='animatedText_${currIndex}' style='${animationCSS}'>${curr}</span><span>&nbsp;</span>`;
      }, initialValue);

      if (!!highlight) {      
        const regexp = new RegExp(`${highlight}`,"g");
        return {__html: message.replaceAll(regexp, (match) => {return `<strong style='color:#fff'>${match}</strong>`})};
      }
    } catch (error) {
      console.error("Error parsing hero title highlighting. Returning normal title without highlights.");
    }
    return "";
  })();
  return (
    <>
      <Helmet>
        {Object.entries(breakpoints).map( ([bp, width]) => (
            <link key={`background-hero-${bp}`} 
              rel="preload"
              as="image"
              fetchpriority="high"
              media={`${mediaQueryForBreakpoint(bp, width)}`}
              imagesrcset={getImageSrcSet(bp, width)}
            />
            ))
          } 
          {/* <link 
            rel="preload"
            as="image"
            fetchpriority="high"
            imagesrcset={getImageSrcSet("", viewportWidth)}
          />  */}
      </Helmet>
      <MantineProvider theme={theme}>
        <div className={wrapperClasses} ref={heroRef}>
          <picture>
            {
            Object.entries(breakpoints).map( ([bp, width]) => (
              <source key={`background-hero-${bp}`} srcSet={getImageSrcSet(bp, width)} media={`${mediaQueryForBreakpoint(bp, width)}`} />
            ))
            } 
            <img src={`${heroSrc}`} alt={`${alt}`} />
          </picture>
          <Overlay color="#000" opacity={0.25} zIndex={1} />
          {heroWidth > 0 ? (
          <div className={classes.inner}>
            <Container className={css.responsiveHeroTitleContainer}>
              <Title className={classes.title}>
                  {<div dangerouslySetInnerHTML={titleMessage} />}               
              </Title>
              <Text size="lg" className={classes.description}>
                <FormattedMessage id={description.id}>
                  {
                    txt => <span id='animatedText_description' style={{animation: `fade-in 1s ${0.5 + animationDelayforDescription}s forwards cubic-bezier(0.11, 0, 0.5, 0)`}}>{txt}</span>
                  }
                </FormattedMessage>
              </Text>
              {!!ctaText ? (
              <div className={classes.controls}>
                <Button 
                  className={classes.control} 
                  variant="subtle"
                  size="md"
                  rightIcon={<IconSearch size="1rem" />}
                  >
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                      '?address=Madeira%20Island%2C%20Portugal&bounds=33.2%2C-16.2%2C32.5%2C-17.3&region=Madeira&sort=-createdAt'
                    }}
                    style={{color: "white", textDecoration: "none"}}>
                      <FormattedMessage id="SectionHero.browseButton" />
                  </NamedLink>
                </Button>
                {/* <Button className={cx(classes.control, classes.secondaryControl)} size="lg">
                  Live demo
                </Button> */}
              </div>
              ) : null}
            </Container>
          </div>
          ): (
            null
          )}
        </div>
      </MantineProvider>
    </>
  )
}

export default React.memo(injectIntl(HeroImageBackground));